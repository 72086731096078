define("ember-loading/services/loading", ["exports", "@ember/service", "@ember/object", "ember-concurrency", "@ember/application", "rsvp", "ember-concurrency-ts"], function (_exports, _service, _object, _emberConcurrency, _application, _rsvp, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function parseArgs() {
    let length = arguments.length;
    let args;
    let method;
    let target;
    if (length === 1) {
      target = null;
      method = arguments[0];
    } else if (length > 1) {
      let argsIndex = 2;
      let methodOrTarget = arguments[0];
      let methodOrArgs = arguments[1];
      let type = typeof methodOrArgs;
      if (type === 'function') {
        target = methodOrTarget;
        method = methodOrArgs;
      } else if (methodOrTarget !== null && type === 'string' && methodOrArgs in methodOrTarget) {
        target = methodOrTarget;
        method = target[methodOrArgs];
      } else if (typeof methodOrTarget === 'function') {
        argsIndex = 1;
        target = null;
        method = methodOrTarget;
      }
      if (length > argsIndex) {
        let len = length - argsIndex;
        args = new Array(len);
        for (let i = 0; i < len; i++) {
          args[i] = arguments[i + argsIndex];
        }
      }
    }
    return [target, method, args];
  }
  let LoadingService = _exports.default = (_class = class LoadingService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "postDelay", 0);
      _defineProperty(this, "preDelay", 0);
      _defineProperty(this, "watchTransitions", true);
      _defineProperty(this, "_routerTransitionDeferred", void 0);
    }
    get isLoading() {
      return (0, _emberConcurrencyTs.taskFor)(this._runJob).isRunning;
    }
    get showLoading() {
      return !(0, _emberConcurrencyTs.taskFor)(this.preDelayTask).isRunning && (this.isLoading || (0, _emberConcurrencyTs.taskFor)(this.postDelayTask).isRunning);
    }
    _routeWillChange() {
      let deferred = (0, _rsvp.defer)();
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
      }
      this._routerTransitionDeferred = deferred;
      this.run(() => deferred.promise);
    }
    _routeDidChange() {
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
        this._routerTransitionDeferred = undefined;
      }
    }
    init() {
      super.init();
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-loading'];
      if (config) {
        this.preDelay = config.preDelay || 0;
        this.postDelay = config.postDelay || 0;
        this.watchTransitions = config.watchTransitions !== false;
      }
      if (this.watchTransitions) {
        this.router.on('routeWillChange', this._routeWillChange);
        this.router.on('routeDidChange', this._routeDidChange);
      }
    }
    willDestroy() {
      super.willDestroy();
      if (this.watchTransitions) {
        this.router.off('routeWillChange', this._routeWillChange);
        this.router.off('routeDidChange', this._routeDidChange);
      }
    }

    // @todo Revisit this stronger typing when https://github.com/typed-ember/ember-cli-typescript/issues/590 is resolved
    //
    // run<T, P1, P2, P3, P4, P5, P6, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<T, P1, P2, P3, P4, P5, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<T, P1, P2, P3, P4, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<T, P1, P2, P3, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3) => R) | keyof T, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<T, P1, P2, R>(target: T, fn: ((p1: P1, p2: P2) => R) | keyof T, p1: P1, p2: P2): Promise<R>;
    // run<T, P1, R>(target: T, fn: ((p1: P1) => R) | keyof T, p1: P1): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>
    // run<P1, P2, P3, P4, P5, P6, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<P1, P2, P3, P4, P5, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<P1, P2, P3, P4, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4) => R, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<P1, P2, P3, R>(fn: (p1: P1, p2: P2, p3: P3) => R, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<P1, P2, R>(fn: (p1: P1, p2: P2) => R, p1: P1, p2: P2): Promise<R>;
    // run<P1, R>(fn: (p1: P1) => R, p1: P1): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    // run<T, R>(target: T, fn: ((...args: any[]) => R) | keyof T, ...args: any[]): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>;
    // run<R>(fn: (...args: any[]) => R, ...args: any[]): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    async run(...args) {
      if (this.preDelay > 0) {
        (0, _emberConcurrencyTs.taskFor)(this.preDelayTask).perform(this.preDelay);
      }
      let result = await (0, _emberConcurrencyTs.taskFor)(this._runJob).perform(...args);
      if (this.postDelay > 0) {
        (0, _emberConcurrencyTs.taskFor)(this.postDelayTask).perform(this.postDelay);
      }
      return result;
    }
    *_runJob(...args) {
      let [target, method, realArgs] = parseArgs(...args);
      return yield method.apply(target, realArgs);
    }
    *preDelayTask(delay) {
      yield (0, _emberConcurrency.timeout)(delay);
    }
    *postDelayTask(delay) {
      yield (0, _emberConcurrency.timeout)(delay);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_runJob", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "_runJob"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preDelayTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "preDelayTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postDelayTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "postDelayTask"), _class.prototype)), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});