define("@fleetbase/fleetops-data/utils/geojson/feature", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@ember/polyfills"], function (_exports, _geoJson, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Feature extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'Feature') {
        (0, _polyfills.assign)(this, input);
      } else if (input && input.type && input.coordinates) {
        this.geometry = input;
      } else {
        throw 'GeoJSON: invalid input for new Feature';
      }
      this.type = 'Feature';
    }
  }
  _exports.default = Feature;
});