define("@fleetbase/fleetops-data/serializers/entity", ["exports", "@fleetbase/ember-core/serializers/application", "@ember-data/serializer/rest", "@ember/utils"], function (_exports, _application, _rest, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EntitySerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        payload: {
          serialize: 'ids'
        },
        destination: {
          embedded: 'always'
        },
        trackingNumber: {
          embedded: 'always'
        },
        driver: {
          embedded: 'always'
        },
        photo: {
          embedded: 'always'
        },
        facilitator: {
          embedded: 'always'
        },
        customer: {
          embedded: 'always'
        }
      };
    }
    serializeBelongsTo(snapshot, json, relationship) {
      let key = relationship.key;
      if (key === 'payload' || key === 'facilitator' || key === 'customer' || key === 'driver') {
        return;
      }
      super.serializeBelongsTo(...arguments);
    }
    serializePolymorphicType(snapshot, json, relationship) {
      let key = relationship.key;
      let belongsTo = snapshot.belongsTo(key);
      let type = belongsTo.modelName;

      // if snapshot already has type filled respect manual input
      const isPolymorphicTypeBlank = (0, _utils.isBlank)(snapshot.attr(key + '_type'));
      if (isPolymorphicTypeBlank) {
        key = this.keyForAttribute ? this.keyForAttribute(key, 'serialize') : key;
        if (!(0, _utils.isBlank)(belongsTo.attr(`${key}_type`))) {
          type = belongsTo.attr(`${key}_type`);
        }
        if (!belongsTo) {
          json[key + '_type'] = null;
        } else {
          json[key + '_type'] = `fleet-ops:${type}`;
        }
      }
    }
  }
  _exports.default = EntitySerializer;
});