define("@fleetbase/fleetops-data/utils/geojson/point", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@ember/polyfills", "@ember/array"], function (_exports, _geoJson, _polyfills, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Point extends _geoJson.default {
    constructor(input) {
      super();
      var args = Array.prototype.slice.call(arguments);
      if (input && input.type === 'Point' && input.coordinates) {
        (0, _polyfills.assign)(this, input);
      } else if (input && (0, _array.isArray)(input)) {
        this.coordinates = input;
      } else if (args.length >= 2) {
        this.coordinates = args;
      } else {
        throw 'GeoJSON: invalid input for new Point';
      }
      this.type = 'Point';
    }
  }
  _exports.default = Point;
});