define("@fleetbase/fleetops-data/models/service-area", ["exports", "@ember-data/model", "@ember/object", "date-fns", "@fleetbase/ember-core/utils/extract-coordinates", "@fleetbase/ember-core/utils/get-with-default", "@fleetbase/ember-core/utils/first"], function (_exports, _model, _object, _dateFns, _extractCoordinates, _getWithDefault, _first) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ServiceAreaModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.hasMany)('zone', {
    async: false
  }), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('multi-polygon'), _dec12 = (0, _model.attr)('date'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('date'), _dec15 = (0, _object.computed)('border', 'boundaries'), _dec16 = (0, _object.computed)('border.coordinates.[]'), _dec17 = (0, _object.computed)('bounds'), _dec18 = (0, _object.computed)('bounds'), _dec19 = (0, _object.computed)('updated_at'), _dec20 = (0, _object.computed)('updated_at'), _dec21 = (0, _object.computed)('updated_at'), _dec22 = (0, _object.computed)('created_at'), _dec23 = (0, _object.computed)('created_at'), _dec24 = (0, _object.computed)('created_at'), (_class = class ServiceAreaModel extends _model.default {
    constructor(...args) {
      super(...args);
      /** @ids */
      _initializerDefineProperty(this, "public_id", _descriptor, this);
      _initializerDefineProperty(this, "company_uuid", _descriptor2, this);
      _initializerDefineProperty(this, "parent_uuid", _descriptor3, this);
      /** @relationships */
      _initializerDefineProperty(this, "zones", _descriptor4, this);
      /** @attributes */
      _initializerDefineProperty(this, "name", _descriptor5, this);
      _initializerDefineProperty(this, "type", _descriptor6, this);
      _initializerDefineProperty(this, "country", _descriptor7, this);
      _initializerDefineProperty(this, "color", _descriptor8, this);
      _initializerDefineProperty(this, "stroke_color", _descriptor9, this);
      _initializerDefineProperty(this, "status", _descriptor10, this);
      _initializerDefineProperty(this, "border", _descriptor11, this);
      /** @dates */
      _initializerDefineProperty(this, "deleted_at", _descriptor12, this);
      _initializerDefineProperty(this, "created_at", _descriptor13, this);
      _initializerDefineProperty(this, "updated_at", _descriptor14, this);
    }
    /** @computed */
    get bounds() {
      const polygon = this.border.get(0);
      const coordinates = (0, _getWithDefault.default)(polygon, 'coordinates', []);
      const bounds = (0, _first.default)(coordinates);
      return bounds.map(coord => (0, _extractCoordinates.default)(coord));
    }
    get boundaries() {
      return (0, _getWithDefault.default)(this.border, 'coordinates', []);
    }
    get firstCoordinatePair() {
      return (0, _first.default)(this.bounds) ?? [0, 0];
    }
    get centerCoordinates() {
      const x = this.bounds.map(xy => xy[0]);
      const y = this.bounds.map(xy => xy[1]);
      const cx = (Math.min(...x) + Math.max(...x)) / 2;
      const cy = (Math.min(...y) + Math.max(...y)) / 2;
      return [cx, cy];
    }
    get updatedAgo() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }
    get updatedAt() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }
    get updatedAtShort() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }
    get createdAgo() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }
    get createdAt() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }
    get createdAtShort() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PP');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "zones", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "stroke_color", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "border", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bounds", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "bounds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "boundaries", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "boundaries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstCoordinatePair", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "firstCoordinatePair"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "centerCoordinates", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "centerCoordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype)), _class));
});