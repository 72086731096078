define("@fleetbase/fleetops-data/utils/geojson/line-string", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@ember/polyfills", "@ember/array"], function (_exports, _geoJson, _polyfills, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LineString extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'LineString' && input.coordinates) {
        (0, _polyfills.assign)(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.coordinates = input;
      } else {
        throw 'GeoJSON: invalid input for new LineString';
      }
      this.type = 'LineString';
    }
    addVertex(point) {
      this.coordinates.push(point);
      return this;
    }
    insertVertex(point, index) {
      this.coordinates.splice(index, 0, point);
      return this;
    }
    removeVertex(remove) {
      this.coordinates.splice(remove, 1);
      return this;
    }
  }
  _exports.default = LineString;
});